import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { ISaveWaterCardProps } from './models';

import './SaveWaterCard.scss';

const SaveWaterCard: FC<ISaveWaterCardProps> = ({
  subTitle,
  title,
  text,
  image,
  link,
  linkLabel,
}) => (
  <div className="save-water-card__wrapper">
    <div className="save-water-card__text-block">
      {subTitle ? <div className="save-water-card__subtitle">{subTitle}</div> : null}
      {title ? <div className="save-water-card__title">{title}</div> : null}
      <DangerouslySetInnerHtml className="save-water-card__text" html={text} />
      {link?.[0]?.url && linkLabel ? (
        <Button
          to={link[0].url}
          target={link?.[0]?.target}
          variant="link"
          classes="save-water-card__link"
          ariaLabel={`${linkLabel} ${title}`}
        >
          {linkLabel}
        </Button>
      ) : linkLabel ? (
        <span className="save-water-card__link--placeholder">{linkLabel}</span>
      ) : null}
    </div>
    <GatsbyImage
      image={image[0].properties.image}
      alt={image[0].properties.imageAlt}
      className="save-water-card__image"
      objectFit="cover"
      objectPosition="right"
    />
  </div>
);

export const query = graphql`
  fragment FragmentSaveWaterCard on TSaveWaterCard {
    properties {
      subTitle
      title
      text
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      link {
        url
        target
      }
      linkLabel
    }
  }
`;

export default SaveWaterCard;
