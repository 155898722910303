import React, { FC } from 'react';

import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import WithCarousel from 'components/WithCarousel';
import SaveWaterCard from 'components/SaveWaterCard';

import settings from './constants';
import { IWaterWorkProps } from './model';

import './WaterWork.scss';

const WaterWork: FC<IWaterWorkProps> = ({ mainItem, items, ariaNext, ariaPrev }) => {
  const { title, text, link, image, linkLabel } = mainItem[0].properties;
  const { url, target } = link?.[0] || {};
  const waterWorkCards = items.map((card) => ({ ...card.properties }));

  return (
    <section className="water-work" data-testid="WaterWork">
      <div className="water-work__main-item">
        <div className="water-work__main-item-content">
          <div className="water-work__main-item-title">{title}</div>
          <DangerouslySetInnerHtml className="water-work__main-item-text" html={text} />
          {url && linkLabel ? (
            <Button to={url} target={target} variant="link" ariaLabel={`${linkLabel} ${title}`} classes="water-work__main-item-link">
              {linkLabel}
            </Button>
          ) : linkLabel ? (
            <span className="water-work__main-item-link--placeholder">{linkLabel}</span>
          ) : null}
        </div>
        <div className="water-work__main-item-image">
          <GatsbyImage
            image={image[0].properties.image}
            alt={image[0].properties.imageAlt}
            objectFit="cover"
          />
        </div>
      </div>

      <WithCarousel
        ariaNext={ariaNext}
        ariaPrev={ariaPrev}
        slideComponent={SaveWaterCard}
        slides={waterWorkCards}
        settings={settings}
      />
    </section>
  );
};

export default WaterWork;
